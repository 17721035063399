.alarms-table-style {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.alarms-table-style > * .ant-table-thead > tr > th {
  background-color: #131313 !important;
  color: #eeeeee !important;
}
.alarms-table-style > * .ant-table-tbody > tr > td {
  background-color: var(--sidebarColor) !important;
  color: #eeeeee !important;
}

.alarms-table-title {
  font-size: 21px;
  color: white;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
