.maintenance-table-style {
  margin-right: 0x !important;
}

.maintenance-table-style > * .ant-table-thead > tr > th {
  background-color: #131313 !important;
  color: #eeeeee !important;
}

.maintenance-table-style > * .ant-table-tbody > tr > td {
  background-color: #2b2b2b !important;
  color: #eeeeee !important;
}

.maintenance-picture-style {
  max-height: 50px !important;
}
