.plant-settings-modal > * .ant-modal-header {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
}

.plant-settings-modal > * .ant-modal-title {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
  font-size: 21px !important;
}

.plant-settings-modal > .ant-modal-content {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
}

.plant-settings-modal > * .ant-modal-footer {
  background-color: #1e1e1e !important;
  color: transparent !important;
}

.plant-settings-modal-body-container {
  width: 100%;
}

.plant-settings-menu-container {
  width: 280px;
  height: 50px;
  margin: 5px 0px 10px 0px;
  color: rgb(241, 241, 241);
  border-radius: 5px;
  border: #555555 solid 1px;
  background-color: #272727;
  padding: 12px 15px 12px 15px !important;
}

.plant-settings-menu-container:hover {
  background-color: #555555;
}

.plant-settings-menu-label {
  margin-top: 10px;
  color: rgb(241, 241, 241);
  font-size: 14px;
}


.plant-settings-menu-icon {
  color: rgb(241, 241, 241);
  width: 20px;
  height: 20px;
  float: right;
  margin-top: 5px;
}

.plant-settings-line {
  background: rgb(180, 180, 180);
  height: 1px;
}

.plant-settings-input-style {
  background-color: transparent !important;
  color: #eeeeee !important;
  width: 100%;
}

.plant-settings-auto-button {
  color: #ffffff !important;
  border-color: white !important;
  width: 100%;
  height: 100px !important;
  margin-top: 10px;
  border-radius: 5px !important;
}

.plant-settings-auto-button:disabled {
  color: #797979 !important;
  border-color: #797979 !important;
  width: 100%;
  height: 100px !important;
  margin-top: 10px;
  border-radius: 5px !important;
}

.plant-settings-time-picker {
  color: white;
  width: 100%;
  background-color: transparent !important;
  color: white !important;
}

.plant-settings-task-time {
  color: rgb(243, 243, 243);
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.labor-selector {
  width: 100%;
  color: #eeeeee !important;
}

.labor-selector > .ant-select-arrow {
  color: #eeeeee !important;
}

.labor-selector-dropdown {
  background-color: #353535 !important;
  color: black !important;
}

.labor-selector > .ant-select-selector {
  background-color: transparent !important;
}

.labor-selector-value {
  color: #eeeeee !important;
  background-color: #353535 !important;
}

.labor-selector-value:hover {
  background-color: #575757 !important;
}

.labor-selector-value:active {
  color: #1e1e1e !important;
}
