.machine-item-style {
    
}

.machine-image-tile {
    /* min-height: 100px !important; */
    max-width: 100% !important;
    object-fit: "fill";
    /* height: 120px; */
}

.led-one-tile-style {
    text-align: center;
    font-size: 50px;
}

.main-tile-operator-image-style {
    border-radius: 50%;
    box-shadow: 0px 0px 5px white;
    object-fit: cover;
}