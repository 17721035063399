.custom-button {
  opacity: 1;
  cursor: pointer;
  margin-top: 20px !important;
}
.custom-button:hover {
  opacity: 0.6;
}
.custom-button:active {
  opacity: 0.9;
}

.entry-add-modal {
  /* background-color: #1e1e1e !important; */
}

.entry-add-modal > * .ant-modal-header {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
}

.entry-add-modal > * .ant-modal-title {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
}

.entry-add-modal > .ant-modal-content {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
}

.entry-add-modal > * .ant-modal-footer {
  background-color: #1e1e1e !important;
  color: #eeeeee !important;
}

.date-time-picker-style {
  background-color: #1e1e1e !important;
}
.date-time-picker-style > .ant-picker-input input {
  color: #eeeeee !important;
}
.date-time-picker-style > .ant-picker-input span {
  color: #eeeeee !important;
}

.ant-modal-close {
  color: white !important;
}
