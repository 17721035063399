.cd-add-form-checkbox{
  width: 200px;
  color: white !important;
  margin-top: 5px !important;
  font-size: 16px;
}

.cd-add-form-step3 {
  color: white !important;
  font-size: 16px;
}

.cd-selector-label {
  padding-top: 5px !important;
  color: #eeeeee !important;
  font-size: 15px !important;
}

.cd-machine-selector {
  width: 240px !important;
  margin-left: 10px !important;
  color: #eeeeee !important;
}

.cd-machine-selector > .ant-select-arrow {
  color: #eeeeee !important;
}

.cd-machine-selector-dropdown {
  background-color: #353535 !important;
  color: black !important;
}

.cd-machine-selector > .ant-select-selector {
  background-color: transparent !important;
}

.cd-machine-selector-value {
  color: #eeeeee !important;
  background-color: #353535 !important;
}

.cd-machine-selector-value:hover {
  background-color: #575757 !important;
}

.cd-machine-selector-value:active {
  color: #1e1e1e !important;
}

.cd-number-input {
  color: white !important;
  background-color: transparent !important;
}

.cd-page-no {
  color: white !important;
  font-size: 16px;
  text-align: center;
}

.cd-no-widget{
  margin-top: 100px;
  text-align: center;
  color: white;
  font-size: 20px;
}

.ant-checkbox-disabled{
  color: white !important;
}

