@import (reference) '../../style/themes/index';

@drawer-prefix-cls: ~'@{ant-prefix}-drawer';

.panel-motion {
  &-enter,
  &-appear,
  &-leave {
    &-active {
      transition: all @animation-duration-slow;
    }
  }
}

.@{drawer-prefix-cls} {
  // ======================== Mask ========================
  &-mask-motion {
    &-enter,
    &-appear,
    &-leave {
      &-active {
        transition: all @animation-duration-slow;
      }
    }

    &-enter,
    &-appear {
      opacity: 0;

      &-active {
        opacity: 1;
      }
    }

    &-leave {
      opacity: 1;

      &-active {
        opacity: 0;
      }
    }
  }

  // ======================= Panel ========================
  &-panel-motion {
    // Left
    &-left {
      .panel-motion();

      &-enter,
      &-appear {
        transform: translateX(-100%);

        &-active {
          transform: translateX(0);
        }
      }

      &-leave {
        transform: translateX(0);

        &-active {
          transform: translateX(-100%);
        }
      }
    }

    // Right
    &-right {
      .panel-motion();

      &-enter,
      &-appear {
        transform: translateX(100%);

        &-active {
          transform: translateX(0);
        }
      }

      &-leave {
        transform: translateX(0);

        &-active {
          transform: translateX(100%);
        }
      }
    }

    // Top
    &-top {
      .panel-motion();

      &-enter,
      &-appear {
        transform: translateY(-100%);

        &-active {
          transform: translateY(0);
        }
      }

      &-leave {
        transform: translateY(0);

        &-active {
          transform: translateY(-100%);
        }
      }
    }

    // Bottom
    &-bottom {
      .panel-motion();

      &-enter,
      &-appear {
        transform: translateY(100%);

        &-active {
          transform: translateY(0);
        }
      }

      &-leave {
        transform: translateY(0);

        &-active {
          transform: translateY(100%);
        }
      }
    }
  }
}
