.hennig-detail-page-container-style {
  color: #eeeeee !important;
  padding: 25px;
  background: var(--sidebarColor);
  border-radius: 5px;
}

.hennig-detail-current-info {
  text-align: center;
  font-size: 18px;
  color: white;
  margin: 10px;
  display: block;
}

.hennig-backward-button {
  color: #eeeeee !important;
  font-size: 18px !important;
  font-weight: bold !important;
  margin-bottom: 15px;
  margin-top: 3px;
}

.hennig-detail-top {
  border-radius: 5px;
  padding: 20px;
  background: #1e1e1e !important;
}

.hennig-detail-one-hennig-container {
  border-radius: 5px;
  padding: 20px;
  background: #1e1e1e !important;
}

.hennig-detail-current-value{
  padding: 7px;
}

.hennig-detail-current-value-container{
  color: #eeeeee !important;
  font-size: 18px !important;
  border: #dddddd solid 1px;
  border-radius: 5px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.hennig-detail-current-value-border-top{
  border-top: #b9b9b9 solid 1px;
}

.hennig-detail-current-value-border-right{
  border-right: #b9b9b9 solid 1px;
}

.hennig-detail-one-hennig-name {
  font-size: 18px;
  font-weight: bold;
}

.hennig-detail-one-hennig-text {
  margin-top: 5px;
  font-size: 16px;
}

.hennig-description-container {
  border-radius: 10px;
  padding: 0px 20px 0px 20px;
  background: #1e1e1e !important;
}

.hennig-detail-page-image-style {
  height: 150px;
  background-color: transparent;
}

.detail-page-title {
  font-weight: bold;
  font-size: 24px;
}

.detail-page-trend-container {
  margin-top: 20px;
}

.hennig-trend-chart-title {
  text-align: center;
  font-size: 20px;
  color: white;
  margin-top: 15px;
  font-weight: 800;
}

.henig-detail-page-trend-title {
  font-weight: bold;
  font-size: 18px;
  padding-top: 15px;
  text-align: center;
}

.hennig-detail-page-trend-chart {
  margin-top: 10px;
  background-color: transparent !important;
}

.detail-page-export-button {
  background: #181818;
  border-color: #dddddd;
  border-width: 1px;
  width: 150px;
  height: 50px;
  border-radius: 5px;
}

.detail-page-export-button:hover {
  background-color: #353535;
  cursor: pointer;
}

.detail-page-one-date {
  background: transparent !important;
}

.detail-page-container-info-container {
}

.hennig-backward-button :hover {
  color: #f5dbdb !important;
  /* border: 1px solid #1e1e1e !important; */
}

.hennig-detail-one-hennig-location {
  margin-top: 5px;
}

.hennig-detail-one-hennig-info-title {
  font-size: 17px;
  display: inline-block;
  width: 100px;
}

.hennig-detail-one-hennig-info-value {
  margin-left: 10px;
  font-size: 17px;
  display: inline-block;
}

.hennig-detail-one-hennig-status-title {
  display: inline-block;
}

.hennig-detail-one-hennig-status-value {
  margin-left: 10px;
  display: inline-block;
}

.flex-container {
  padding: 0;
  margin: 0;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.nowrap {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.delete-hennig-button {
  text-align: right;
  margin-bottom: 5px;
  border-radius: 5px !important;
}
