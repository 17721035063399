.cnc-backbround{
    background-color: rgb(36, 37, 37) !important; 
}

.ant-spin-dot-item{
    background-color: #DADAB3 !important;
}
.content-logo-container{
    /* position: absolute; */
    bottom: 10px;
    /* width: 50%; */
    /* border: 3px solid #8AC007; */
}

.coming-soon-page{
    color: white;
    font-size: 30px;
    text-align: center;
    margin-top: 150px;
}


.list-tile-switch-container {
    /* width: 110px; */
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-right: 20px;
}

.list-tile-switch {
    max-width: 15px !important;
    background-color: #1e1e1e !important;
}

.list-tile-switch.ant-switch-checked {
    background-color: rgb(99, 8, 245) !important;

}

.list-tile-switch-title {
    color: white;
    margin-left: 5px;
}