.table-widget-table-style {
  margin-left: 20px !important;
  margin-right: 20px !important;
  overflow-x: scroll !important;
}

.table-widget-table-style > * .ant-table-thead > tr > th {
  background-color: #131313 !important;
  color: #eeeeee !important;
}

.table-widget-table-style > * .ant-table-tbody > tr > td {
  background-color: #2b2b2b !important;
  color: #eeeeee !important;
}

.table-widget-table-back {
  margin: 0px 10px !important;
  /* height: 150px; */
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(#313131, #1b1b1b);
}

.table-widget-table-title {
  font-size: 21px;
  color: white;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
}

.table-widget-print-button {
  margin-top: 15px;
  margin-right: 20px;
  border-radius: 5px !important;
  float: right;
}

.table-print-button {
  font-size: 20px;
  color: white;
  border: 0px;
  text-align: start;
  padding-left: 7px;
}

.ant-table-cell {
  /* white-space: break-spaces !important; */
}

.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgb(255 255 255 / 25%);
  background: #ffffff00;
  border-color: #706f6f;
}

.table-wrapper table {
  table-layout: fixed !important; /* rewrite inline styles */
}