.customername-title-style {
    color: #eeeeee !important;
    display: inline-block;
    margin-right: 10px !important;
}

.customername-value-container-style {
    display: inline-block;
    margin-right: 10px !important;
}

.customername-value-style {
    background-color: transparent !important;
    color: #eeeeee !important;
    width: 300px !important;
}